<template>
  <div class="container mx-auto">
    <div class="md:w-1/2 mx-auto">
      <h1>{{ t('pages.passwordReset.reset.title') }}</h1>
      <form @submit.prevent="resetPassword">
        <div class="flex flex-col">
          <label for="password" v-text="t('user.password.label')" />
          <input id="password" v-model="password" type="password" required>
        </div>
        <div class="text-right">
          <button class="mt-4 w-64" type="submit">
            <spinner v-if="loading" />
            <template v-else>
              {{ t('pages.passwordReset.reset.submit') }}
            </template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { useRouter, useRoute } from 'vue-router';
import Spinner from '@/components/utils/spinner.vue';
import axios from '@/services/axios';

export default {
  name: 'PasswordResetPage',
  components: { Spinner },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const token = ref('');
    const password = ref('');
    const loading = ref(false);

    onMounted(() => {
      token.value = route.params.token;
    });

    const resetPassword = async () => {
      loading.value = true;
      try {
        await axios.post('/auth/forgot-password/reset', {
          token: token.value,
          plainPassword: password.value,
        });
        notify(t('pages.passwordReset.reset.success'));
        await router.push('/');
      } catch (err) {
        notify({
          title: t('pages.passwordReset.reset.error'),
          text: err?.response?.['hydra:description'] ?? null,
          type: 'error',
        });
      }
      loading.value = false;
    };

    return {
      t,
      password,
      loading,
      resetPassword,
    };
  },
};
</script>
