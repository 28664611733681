<template>
  <div class="container mx-auto">
    <div class="md:w-1/2 mx-auto">
      <h1>{{ t('pages.login.title') }}</h1>
      <form @submit.prevent="login">
        <div class="flex flex-col">
          <label for="email" v-text="t('user.email.label')" />
          <input id="email" v-model="email" required>
        </div>
        <div class="flex flex-col mt-4">
          <label for="password" v-text="t('user.password.label')" />
          <input
            id="password"
            v-model="password"
            type="password"
            required
          >
          <small>
            <router-link to="/password_reset">
              Passwort vergessen
            </router-link>
          </small>
        </div>
        <div class="text-right">
          <button class="mt-4 w-32" type="submit">
            <spinner v-if="loading" />
            <template v-else>
              {{ t('general.action.login') }}
            </template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import Spinner from '@/components/utils/spinner.vue';

export default {
  name: 'LoginPage',
  components: { Spinner },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const email = ref('');
    const password = ref('');
    const loading = ref(false);

    const login = async () => {
      loading.value = true;
      try {
        await store.dispatch('auth/LOGIN', {
          email: email.value,
          password: password.value,
        });
        notify(t('pages.login.loginSuccess'));
        await router.push('/');
      } catch (err) {
        console.error(err, err.message, err.response);
        notify({
          title: t('pages.login.error'),
          text: err?.response?.data?.['hydra:description'] ?? null,
          type: 'error',
        });
      }
      loading.value = false;
    };

    return {
      t,
      email,
      password,
      loading,
      login,
    };
  },
};
</script>
