<template>
  <div class="container mx-auto">
    <div class="md:w-1/2 mx-auto">
      <h1>{{ t('pages.passwordReset.request.title') }}</h1>
      <div v-if="response.error" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800">
        {{ response.message }}
      </div>
      <div v-if="response.success" class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800">
        {{ t('pages.passwordReset.request.success') }}
      </div>
      <form @submit.prevent="resetPassword">
        <div class="flex flex-col">
          <label for="email" v-text="t('user.email.label')" />
          <input id="email" v-model="email" required>
        </div>
        <div class="text-right">
          <button class="mt-4 w-64" type="submit" :disabled="loading">
            <spinner v-if="loading" />
            <template v-else>
              {{ t('general.action.reset') }}
            </template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';

export default {
  name: 'PasswordResetRequestPage',
  components: { Spinner },
  setup() {
    const { t } = useI18n();
    const email = ref('');
    const loading = ref(false);
    const response = ref({
      error: false,
      success: false,
      message: '',
    });

    const resetPassword = async () => {
      loading.value = true;
      try {
        await axios.post('/auth/forgot-password/request', { email: email.value });
        response.value = {
          ...response.value,
          success: true,
        };
      } catch (err) {
        response.value = {
          error: true,
          success: false,
          message: err?.response?.['hydra:description'] ?? t('pages.passwordReset.reset.error'),
        };
      }
      loading.value = false;
      email.value = '';
    };

    return {
      t,
      email,
      loading,
      response,
      resetPassword,
    };
  },
};
</script>
